import React from 'react';

export default function() {
  return (
    <section className="t-result__bottom" id="bottom">
      <div className="c-bg c-bg--white">
        <div className="c-bg__inner">
          <div className="c-grid">
            <div className="c-grid__row">
              <div className="c-grid__col c-spacer--pt70 c-spacer--pb60 c-spacer--pt70-sp c-spacer--pb60-sp">
                <h2 className="u-tac c-spacer--pb30">
                  <span className="p-title--marker">肌に優しいフリー処方</span>
                </h2>
                <ul className="p-inlineList p-inlineList--balls">
                  <li>鉱物油フリー</li>
                  <li>三文字</li>
                  <li>六文字六文字</li>
                  <li>九文字九文字九文字</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="c-bg p-bg--paleBlue">
        <div className="c-bg__inner">
          <div className="p-twoPics p-twoPics--has-sp-margin c-spacer--pt120">
            <picture>
              <source media="(max-width:959px)" srcSet="/images/result/p-twoPics-left-sp.jpg" />
              <img src="/images/result/p-twoPics-left-pc.jpg" alt="" />
            </picture>
            <picture>
              <source media="(max-width:959px)" srcSet="/images/result/p-twoPics-right-sp.jpg" />
              <img src="/images/result/p-twoPics-right-pc.jpg" alt="" />
            </picture>
          </div>
          <div className="t-result__wide-pic c-spacer--pt120">
            <picture>
              <source media="(max-width:959px)" srcSet="/images/result/t-result__wide-pic-bottom-sp.jpg" />
              <img src="/images/result/t-result__wide-pic-bottom-pc.jpg" alt="" />
            </picture>
          </div>
          <div className="c-grid" id="buy">
            <div className="c-grid__row">
              <div className="c-grid__col c-spacer--pt90 c-spacer--pb60">
                <div className="t-result__white-shadow-txt">
                  <p>
                    ■ いつでも解約OK
                    <br />■ 商品到着後14日間全額返金保証付き
                  </p>
                  <p className="c-spacer--pt40">
                    「HOTARU PERSONALIZED」は定期購入サービスです。
                    <br />
                    購入後は、そのときの肌の状態や気分でカスタマイズしなおすことができますので、
                    <br />
                    よりあなたの肌にあったスキンケアがご利用できます。
                  </p>
                  <small className="u-block c-spacer--pt40">
                    ※お届けペースは1ヶ月・2ヶ月からお選びいただけます
                    <br />
                    ※送料が別途かかります。
                    <br />
                    ※金額は税抜き価格となります。
                    <br />
                    ※初回のご注文を受けてから、5～11営業日で出荷しております。営業日は土日祝日を除く平日になります。
                    <br />
                    ※クレジットカード決済、クロネコ代金後払い決済、Amazon Payが決済方法としてご利用になれます。
                  </small>
                </div>
              </div>
            </div>
            <div className="c-grid__row c-spacer--pb60">
              <div className="c-grid__col lg-12 md-6">
                <dl className="p-dlBanner js-smScrollFire js-smScrollFire--fadeUp c-spacer--mb5-sp" data-hook="0.9">
                  <dt>
                    <div className="p-dlBanner__label">PRICE</div>
                  </dt>
                  <dd>
                    <p className="p-dlBanner__ttl">
                      <span>¥6,800</span>
                      <small>/+TAX</small>
                    </p>
                  </dd>
                </dl>
              </div>
              <div className="c-grid__col lg-12 md-6">
                <dl className="p-dlBanner js-smScrollFire js-smScrollFire--fadeUp" data-hook="0.8">
                  <dt>
                    <div className="p-dlBanner__label">PERIOD</div>
                  </dt>
                  <dd>
                    <p className="p-dlBanner__ttl">定期出荷</p>
                    <small>
                      お届けペースは1ヶ月・2ヶ月から
                      <br className="u-hide-pc" />
                      お選びいただけます
                    </small>
                  </dd>
                </dl>
              </div>
            </div>
            <div className="c-grid__row c-spacer--pb120 c-spacer--pb70-sp">
              <div className="c-grid__col js-smScrollFire js-smScrollFire--fadeUp" data-hook="0.9">
                <button className="p-button p-button--circle-right p-button--tall" type="button">
                  <span className="p-button__label p-button__label--camel-font-size">
                    あなたのスキンケアを
                    <span className="p-button__label--large u-block-sp c-spacer--pt5-sp">購入する</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <a className="p-pageBackBtn" href="/">
            <div className="c-grid">
              <div className="c-grid__row">
                <div className="c-grid__col">
                  <span className="p-pageBackBtn__inner">BACK TO TOP</span>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </section>
  );
}
