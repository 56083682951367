import React from "react"
// import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import T_RESULT_FIXED from "../../components/result/t-result__fixed"
import T_RESULT_ANALYSIS from "../../components/result/t-result__analysis"
import T_RESULT_MOISTURLIZER from "../../components/result/t-result__moisturlizer"
import T_RESULT_LOTION from "../../components/result/t-result__lotion"
import T_RESULT_BOTTOM from "../../components/result/t-result__bottom"

const HotaruResultPage = () => (
  <Layout sectionClasses={"t-result js-result"} dataNamespace={"result"} isShowFooter={true}>
    <SEO title="カスタマイズ結果" />
    <div className="c-heroHeader js-heroHeader">
      <div className="c-heroHeader__bg js-heroHeader__bg js-result__fv">
        <div className="t-result__fv">
          <h1 className="t-result__fv__pic">
            <picture>
              <source media="(max-width:959px)" srcSet={"/images/result/t-result__fv__pic-sp.jpg"} />
                <img className="js-fitimg" src={"/images/result/t-result__fv__pic-pc.jpg"} alt="FOR KANAKO SKINCARE SET" />
            </picture>
          </h1>
        </div>
      </div>
      <div className="c-heroHeader__inner">
        <T_RESULT_FIXED />
        <T_RESULT_ANALYSIS />
        <T_RESULT_MOISTURLIZER />
        <hr className="p-hr" />
        <T_RESULT_LOTION />
        <T_RESULT_BOTTOM />
      </div>
    </div>
  </Layout>
)

export default HotaruResultPage
