import React from "react";

import result from '../../pug/_object/_project/_result/_t-result__lotion.pug';

class T_Result_Lotion extends React.Component {
    render() {
        const lotion_type = '油分と水分のバランスを整った落ち着き肌へ';
        const lotion_effect_type = 'moist'; // moist || normal || refresh
        const main_ingridients = [
            // id for: images/common/ingridients/ingridient-{id}.jpg
            { id: '01', name: 'アカツメクサ花エキス1', caption: '皮脂の酸化抑制1' },
            { id: '02', name: 'アカツメクサ花エキス2', caption: '皮脂の酸化抑制2' },
            { id: '03', name: 'アカツメクサ花エキス3', caption: '皮脂の酸化抑制3' }
        ];
        const other_ingridients = [
            '加水分解酵母エキス',
            'スクワラン',
            'グリセリン'
        ];
        return result.call(this, {        // ← use transpiled function
            lotion_type,
            lotion_effect_type,
            main_ingridients,
            other_ingridients
            // // variables
            // items,
            // period,
            // // components
            // ItemDetail,
        });;
    }
}
export default T_Result_Lotion