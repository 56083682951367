import React from 'react';

export default function(__params = {}) {
  const { essences, moisturlizer_type, other_ingridients } = __params;
  return (
    <section className="t-result__moisturlizer" id="moisturlizer">
      <div className="c-bg p-bg--paleBlue">
        <div className="c-bg__inner c-spacer--pt120">
          <div className="c-grid">
            <div className="c-grid__row">
              <div className="c-grid__col c-spacer--pb60">
                <h2 className="p-title--center-block p-title--white-shadow">
                  <span>YOUR SKINCARE</span>
                  <span className="p-title--center-block__main js-textEffect js-textEffect--type1" data-hook="0.8">
                    MOISTURLIZER
                  </span>
                  <span>
                    あなたの肌に最適な
                    <br className="u-hide-pc" />
                    エッセンスチューブと乳液をミックス
                  </span>
                </h2>
              </div>
            </div>
          </div>
          <div className="c-spacer--pb60">
            <div className="p-mixMoisturlizer js-mixMoisturlizer">
              <div className="p-mixMoisturlizer__tubes js-mixMoisturlizer__tubes">
                <img src="/images/result/p-mixMoisturlizer__tubes.png" alt="" />
              </div>
              <div className="p-mixMoisturlizer__center">
                <div className="p-mixMoisturlizer__tubeLeft">
                  <img
                    className="js-mixMoisturlizer__tubeLeft"
                    src={'/images/common/tubes/tube-' + essences[0].id + '.png'}
                    alt=""
                  />
                  <div className="p-mixMoisturlizer__pointline js-mixMoisturlizer__lineLeft">
                    <div className="p-hr p-hr--dot"></div>
                  </div>
                </div>
                <div className="p-mixMoisturlizer__tubeRight">
                  <img
                    className="js-mixMoisturlizer__tubeRight"
                    src={'/images/common/tubes/tube-' + essences[1].id + '.png'}
                    alt=""
                  />
                  <div className="p-mixMoisturlizer__pointline js-mixMoisturlizer__lineRight">
                    <div className="p-hr p-hr--dot"></div>
                  </div>
                </div>
                <div className="p-mixMoisturlizer__bottle js-mixMoisturlizer__bottle">
                  <picture>
                    <source media="(max-width:959px)" srcSet="/images/result/p-mixMoisturlizer__bottle-sp.png" />
                    <img src="/images/result/p-mixMoisturlizer__bottle-pc.png" alt="" />
                  </picture>
                </div>
              </div>
            </div>
          </div>
          <div className="c-grid">
            <div className="c-grid__row">
              <div className="c-grid__col c-spacer--pb60">
                <h3 className="p-title--white-banner">
                  <span>MOISTURIZER TYPE</span>
                  <span className="p-title--white-banner__main">{moisturlizer_type}</span>
                </h3>
              </div>
            </div>
          </div>
          <div className="t-result__wide-pic c-spacer--pb80 c-spacer--pb50-sp">
            <picture>
              <source media="(max-width:959px)" srcSet="/images/result/t-result__wide-pic-moisturlizer-sp.jpg" />
              <img src="/images/result/t-result__wide-pic-moisturlizer-pc.jpg" alt="" />
            </picture>
          </div>
          <div className="c-grid">
            <div className="c-grid__row">
              <div className="c-grid__col c-spacer--pb20 c-spacer--pb20-sp">
                <h4 className="t-result__white-shadow-txt p-title--20 u-tac">
                  あなたのMOISTURIZERへ
                  <br className="u-hide-pc" />
                  調合されたエッセンスチューブ
                </h4>
              </div>
            </div>
          </div>
          {(essences || []).map((essence, i) => (
            <div className={'p-tubeDetail c-spacer--pt60 ' + ('p-tubeDetail--' + essence.color)} key={i}>
              <div className="c-bg">
                <div className="p-tubeDetail__cols">
                  <div className="p-tubeDetail__thumb">
                    <div
                      className={
                        'p-tubeDetail__thumb__num js-smScrollFire js-smScrollFire--rotateY ' + ('type-' + essence.color)
                      }
                    >
                      <span>{essence.id}</span>
                    </div>
                    <div className="p-tubeDetail__thumb__tube js-smScrollFire js-smScrollFire--fadeUp">
                      <img src={'/images/common/tubes/tube-' + essence.id + '.png'} alt="" />
                    </div>
                  </div>
                  <div className="p-tubeDetail__ttl">
                    <div className="p-tubeDetail__ttl__num">{essence.id}</div>
                    <div className="p-title--small-label u-fc--white">TYPE:</div>
                    <h4 className="p-tubeDetail__ttl__type">{essence.type}</h4>
                    <p className="p-tubeDetail__ttl__caption">{essence.caption}</p>
                  </div>
                </div>
                <div className="c-grid c-spacer--pt30 c-spacer--pt70-sp c-spacer--pb60 c-spacer--pb20-sp">
                  <div className="c-grid__row">
                    <div className="c-grid__col c-spacer--pb20 c-spacer--pb15-sp">
                      <div className="p-title--small-label u-fc--white">MAIN INGRIDIENTS:</div>
                    </div>
                  </div>
                  <ul className="p-ingredientBanners c-grid__row">
                    {(essence.ingridients || []).map((ingridient, i) => (
                      <li
                        className="js-smScrollFire js-smScrollFire--fadeUp c-grid__col lg-8 md-4 c-spacer--pb10-sp"
                        key={i}
                        data-hook="0.9"
                      >
                        <div className={'p-ingredientBanner ' + ('p-ingredientBanner--' + essence.color)}>
                          <div className="p-ingredientBanner__thumb">
                            <img src={'/images/common/ingridients/ingridient-' + ingridient.id + '.jpg'} alt="" />
                          </div>
                          <div className="p-ingredientBanner__label">
                            <h5 className="p-ingredientBanner__label__name">{ingridient.name}</h5>
                            <p className="p-ingredientBanner__label__caption">{ingridient.caption}</p>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
          <div className="c-grid">
            <div className="c-grid__row">
              <div className="t-result__white-shadow-txt c-grid__col c-spacer--pt90 c-spacer--pb80">
                <h4 className="p-title--20 u-tac c-spacer--pb20 c-spacer--pb20-sp">その他のMOISTURIZER主要成分</h4>
                <ul className="p-inlineList p-inlineList--circle p-inlineList--sp-block u-tac">
                  {(other_ingridients || []).map((other_ingridient, i) => (
                    <li key={i}>
                      <span>{other_ingridient}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
