import React from "react";

import result from '../../pug/_object/_project/_result/_t-result__fixed.pug';

class T_Result_Fixed extends React.Component {
    render() {
        return result.call(this, {        // ← use transpiled function
            // // variables
            // items,
            // period,
            // // components
            // ItemDetail,
        });;
    }
}
export default T_Result_Fixed