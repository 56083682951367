import React from "react";

import result from '../../pug/_object/_project/_result/_t-result__analysis.pug';

class T_Result_Analysis extends React.Component {
    render() {
        const skincareSet = {
            name: 'エイジング繊細肌',
            caption: '季節の変わり目や環境・体調の変化によって一時的に肌が揺らぎやすいスキンコンディション。今はホルモンバランスの影響も受けやすく、繊細な敏感肌です。'
        }
        const troubleInside = {
            names: '[ "毛穴", "乾燥", "油分", "ゆらぎ", "炎症" ]',
            scores: '[ 40, 80, 20, 60, 100 ]'
        };
        const troubleOutsides = [
            { score: 82, name: "シミ" },
            { score: 70, name: "キメ" },
            { score: 86, name: "しわ" },
            { score: 30, name: "クマ" }
        ];
        const troubleEnvironments = [
            { slug: "dry", name: "空気の乾燥" },
            { slug: "pollen", name: "花粉" },
            { slug: "air", name: "大気汚染" },
            { slug: "uv", name: "紫外線" },
            { slug: "meal", name: "不規則な食事" },
            { slug: "sleep", name: "疲れ・睡眠不足" },
            { slug: "stress", name: "ストレス" },
            { slug: "exercise", name: "運動不足" }
        ];
        return result.call(this, {        // ← use transpiled function
            skincareSet,
            troubleInside,
            troubleOutsides,
            troubleEnvironments,
            // // variables
            // items,
            // period,
            // // components
            // ItemDetail,
        });;
    }
}
export default T_Result_Analysis