import React from 'react';

export default function(__params = {}) {
  const { lotion_effect_type, lotion_type, main_ingridients, other_ingridients } = __params;
  return (
    <section className="t-result__lotion" id="lotion">
      <div className="c-bg p-bg--paleBlue">
        <div className="c-bg__inner">
          <div className="t-result__lotion__bg">
            <div className="t-result__lotion__ripple js-smScrollFire js-smScrollFire--zoomIn" data-hook="0.6">
              <div className="p-lotionLipple js-lotionLipple">
                <div className="p-lotionLipple__effect js-lotionLipple__effect" data-effect={lotion_effect_type}></div>
              </div>
            </div>
            <div className="c-grid">
              <div className="c-grid__row">
                <div className="t-result__lotion__upper c-grid__col c-spacer--pt120 c-spacer--pb80">
                  <h2 className="p-title--center-block p-title--white-shadow">
                    <span>YOUR SKINCARE</span>
                    <span className="p-title--center-block__main js-textEffect js-textEffect--type1" data-hook="0.8">
                      LOTION
                    </span>
                    <span>あなたの肌に最適な成分を配合</span>
                  </h2>
                </div>
                <div className="c-grid__col c-spacer--pb80">
                  <div className="t-result__lotion__bottle js-smScrollFire js-smScrollFire--fadeUp">
                    <picture>
                      <source media="(max-width:959px)" srcSet="/images/result/p-rippleLotion__bottle-sp.png" />
                      <img src="/images/result/p-rippleLotion__bottle-pc.png" alt="" />
                    </picture>
                  </div>
                </div>
                <div className="t-result__lotion__upper c-grid__col c-spacer--pb80">
                  <h3 className="p-title--white-banner">
                    <span>LOTION TYPE</span>
                    <span className="p-title--white-banner__main">{lotion_type}</span>
                  </h3>
                </div>
                <div className="t-result__lotion__upper c-grid__col c-spacer--pb80">
                  <h4 className="t-result__white-shadow-txt p-title--20 u-tac">
                    あなたのLOTIONへ
                    <br className="u-hide-pc" />
                    調合された成分
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="t-result__lotion__upper c-bg p-bg--blue">
            <div className="c-grid c-spacer--pb60 c-spacer--pb20-sp">
              <div className="c-grid__row">
                <div className="c-grid__col c-spacer--pt25 c-spacer--pt35-sp c-spacer--pb25">
                  <div className="p-title--small-label u-fc--white">MAIN INGRIDIENTS:</div>
                </div>
              </div>
              <ul className="p-ingredientBanners c-grid__row">
                {(main_ingridients || []).map((ingridient, i) => (
                  <li
                    className="js-smScrollFire js-smScrollFire--fadeUp c-grid__col lg-8 md-4 c-spacer--pb10-sp"
                    key={i}
                    data-hook="0.9"
                  >
                    <div className="p-ingredientBanner p-ingredientBanner--blue">
                      <div className="p-ingredientBanner__thumb">
                        <img src={'/images/common/ingridients/ingridient-' + ingridient.id + '.jpg'} alt="" />
                      </div>
                      <div className="p-ingredientBanner__label">
                        <h5 className="p-ingredientBanner__label__name">{ingridient.name}</h5>
                        <p className="p-ingredientBanner__label__caption">{ingridient.caption}</p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="t-result__lotion__upper c-grid">
            <div className="c-grid__row">
              <div className="t-result__white-shadow-txt c-grid__col c-spacer--pt90 c-spacer--pb80">
                <h4 className="p-title--20 u-tac c-spacer--pb20 c-spacer--pb20-sp">その他のLOTION主要成分</h4>
                <ul className="p-inlineList p-inlineList--circle p-inlineList--sp-block u-tac">
                  {(other_ingridients || []).map((other_ingridient, i) => (
                    <li key={i}>
                      <span>{other_ingridient}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
