import React from "react";

import result from '../../pug/_object/_project/_result/_t-result__moisturlizer.pug';

class T_Result_Moisturlizer extends React.Component {
    render() {
        const moisturlizer_type = '潤いに満ち透明感が輝く肌へ';
        const essences = [
            {
                id: '01',
                color: 'blue',
                type: 'TIGHTNING',
                caption: 'ハリのある引き締まった\n上向きの肌を目指す',
                ingridients: [
                    // id for: images/common/ingridients/ingridient-{id}.jpg
                    { id: '01', name: 'アカツメクサ花エキス1', caption: '皮脂の酸化抑制1' },
                    { id: '02', name: 'アカツメクサ花エキス2', caption: '皮脂の酸化抑制2' },
                    { id: '03', name: 'アカツメクサ花エキス3', caption: '皮脂の酸化抑制3' }
                ]
            },
            {
                id: '51',
                color: 'purple',
                type: 'BALANCE',
                caption: '揺らぎやすい肌の\nバランスを整える',
                ingridients: [
                    { id: '04', name: 'アカツメクサ花エキス4', caption: '皮脂の酸化抑制4' },
                    { id: '05', name: 'アカツメクサ花エキス5', caption: '皮脂の酸化抑制5' },
                    { id: '06', name: 'アカツメクサ花エキス6', caption: '皮脂の酸化抑制6' }
                ]
            }
        ];
        const other_ingridients = [
            '加水分解酵母エキス',
            'メチルグルセル-10',
            'グリセリン'
        ];
        return result.call(this, {        // ← use transpiled function
            moisturlizer_type,
            essences,
            other_ingridients
            // // variables
            // items,
            // period,
            // // components
            // ItemDetail,
        });;
    }
}
export default T_Result_Moisturlizer