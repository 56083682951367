import React from 'react';

export default function() {
  return (
    <>
      <canvas className="c-floatingImgs is-hide-firstview" id="cvs-floatingImgs">
        <img src="/images/common/c-floatingImgs.png" alt="" />
      </canvas>
      <div className="t-result__fixed">
        <div className="p-fixCircleBtn js-fixCircleBtn js-upDownFire">
          <div className="p-fixCircleBtn__button js-anchor" data-anchor-id="buy" data-anchor-gap="-50">
            <div className="p-fixCircleBtn__text">
              <span>あなたのスキンケアを</span>
              <span>購入する</span>
            </div>
            <div className="p-fixCircleBtn__icon">
              <span className="p-icon--circle-arw-r"></span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
