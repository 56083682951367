import React from 'react';

export default function(__params = {}) {
  const { skincareSet, troubleEnvironments, troubleInside, troubleOutsides } = __params;
  return (
    <section className="t-result__analysis" id="analysis">
      <div className="c-bg c-bg--white">
        <div className="c-bg__inner">
          <div className="p-greyHeading">
            <div className="p-greyHeading__icon">
              <img src="/images/result/p-greyHeading__icon.png" alt="" />
            </div>
            <h2 className="p-greyHeading__ttl">
              <span className="p-greyHeading__ttl__sub">SKINCARE SET</span>
              <span className="p-greyHeading__ttl__main">{skincareSet.name}</span>
            </h2>
          </div>
          <div className="c-grid">
            <div className="c-grid__row c-grid__row--inner-pd-sp">
              <div className="c-grid__col lg-5 md-2 xs-0"></div>
              <div className="c-grid__col lg-14 md-8 c-spacer--pt50 c-spacer--pb50 c-spacer--pt30-sp c-spacer--pb30-sp">
                <p>{skincareSet.caption}</p>
              </div>
            </div>
          </div>
          <hr className="p-hr" />
          <div className="c-grid u-tac">
            <div className="c-grid__row c-spacer--pt60">
              <div className="c-grid__col lg-2 md-1"></div>
              <div className="c-grid__col lg-20 md-10">
                <div className="t-result__analysis__graph">
                  <div className="t-result__analysis__graph__left">
                    <h3 className="p-title--marker">肌内部のトラブル</h3>
                    <div className="t-result__analysis__radar c-spacer--pt20">
                      <canvas
                        className="js-resultRadarChart"
                        id="cvs-resultRadarChart"
                        data-names={troubleInside.names}
                        data-scores={troubleInside.scores}
                      ></canvas>
                    </div>
                  </div>
                  <div className="t-result__analysis__graph__right c-spacer--pt45-sp">
                    <h3 className="p-title--marker">肌表面のトラブル</h3>
                    <ul className="t-result__analysis__pies">
                      {(troubleOutsides || []).map((troubleOutside, i) => (
                        <li key={i}>
                          <div className="c-pieChart js-pieChart" data-score={troubleOutside.score}>
                            <div className="c-pieChart__left js-pieChart__left"></div>
                            <div className="c-pieChart__right js-pieChart__right"></div>
                            <div className="c-pieChart__center">
                              <div className="c-pieChart__center__label">{troubleOutside.name}</div>
                              <div className="c-pieChart__center__value">{troubleOutside.score}</div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="c-grid__row c-spacer--pt70 c-spacer--pt45-sp c-spacer--pb60">
              <div className="c-grid__col lg-3 md-0"></div>
              <div className="c-grid__col lg-18 md-12">
                <h3 className="p-title--marker">肌トラブルを引き起こす環境</h3>
                <div className="t-result__analysis__environment c-spacer--pt30">
                  <ul className="p-environmentBanners p-environmentBanners__type-col3 p-environmentBanners__align-center">
                    {(troubleEnvironments || []).map((troubleEnvironment, i) => (
                      <li key={i}>
                        <div className="p-environmentBanners__inner">
                          <span className={'p-environmentIcon ' + ('type-' + troubleEnvironment.slug)}></span>
                          <span className="p-environmentBanners__name">{troubleEnvironment.name}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
